<template>
  <section class="container">
    <div class="flex flex-col gap-4">
      <h2 class="text-4xl font-bold uppercase text-green-500 md:pb-4 md:text-6xl">
        FAQs
      </h2>
      <Accordion
        title="What is PicUp?"
        :active="activeTab === 1"
        @click="activeTab = 1">
        PicUp is a local delivery service that specialises in moving big and bulky items. We offer a range of services from furniture delivery, rubbish removal,
        and business deliveries. We’re here to make moving your things easy.
      </Accordion>

      <Accordion
        title="Where does PicUp operate?"
        :active="activeTab === 2"
        @click="activeTab = 2">
        Currently, PicUp serves the greater areas of Sydney, Brisbane, and the Gold Coast, including nearby suburbs. We’re actively working on expanding our reach to other cities soon.
      </Accordion>

      <Accordion
        title="How do I schedule a PicUp service?"
        :active="activeTab === 3"
        @click="activeTab = 3">
        Booking with PicUp is a breeze! Just click the link below and fill out our quick, 60-second form. Prefer to talk? Our friendly customer service team is
        available at 1300174287, from Monday to Saturday, 8 am to 4 pm.
      </Accordion>

      <Accordion
        title="What types of items can PicUp transport?"
        :active="activeTab === 4"
        @click="activeTab = 4">
        Our haulers can move almost anything that fits in a ute, large van or truck. Equipped with moving blankets, tie-downs, ratchet straps, and trolleys, we ensure safe transport to your destination. However, please note we can't handle dangerous goods or overly heavy items over 120kgs, like pianos or pool tables. If it can be lifted safely by one or two people, we've got you covered!
      </Accordion>

      <Accordion
        title="How do I pay for PicUp services?"
        :active="activeTab === 5"
        @click="activeTab = 5">
        For your convenience, PicUp accepts various payment methods, including most credit cards, debit cards, Apple Pay, Google Pay, Zip-Pay, Afterpay and invoicing for pre-approved business clients. Please note, we do not accept cash payments. All services must be paid for in full at the time of booking.
      </Accordion>
    </div>
  </section>
</template>

<script setup lang="ts">
const activeTab = ref(1)
</script>
